export function useStaticHeader(
    selector: string,
    stickyElementSelector: string,
    breakpoint: number = 310,
    gap: number = 0
) {
    const referenceHeight = ref(0);
    let resizeListener: (() => void) | undefined;
    let observer: MutationObserver | undefined;

    const setTopForFloatedMenu = () => {
        const referenceElements = Array.from(document.querySelectorAll('[id^="om-"]'))
            .filter(el => {
                const id = el.id;
                return /^om-[a-zA-Z0-9]+$/.test(id);
            });

        if (referenceElements.length === 0) {
            const promoBannerElements = document.querySelectorAll('.promo-banner');
            if (promoBannerElements.length > 0) {
                promoBannerElements.forEach(el => referenceElements.push(el));
            }
        }

        referenceHeight.value =
            window.innerWidth > breakpoint
                ? Array.from(referenceElements)
                    .filter((el) => window.getComputedStyle(el).display === 'block')
                    .reduce(
                        (acc, el) => acc + (el as HTMLElement).offsetHeight,
                        0
                    )
                : 0;

        referenceHeight.value = referenceHeight.value + gap;

        const stickyElement = document.querySelector(
            stickyElementSelector
        ) as HTMLElement;
        if (stickyElement) {
            stickyElement.style.setProperty("--rh", `${referenceHeight.value}px`);
        }
    };

    onMounted(() => {
        setTopForFloatedMenu();

        resizeListener = () => {
            requestAnimationFrame(setTopForFloatedMenu);
        };
        window.addEventListener('resize', resizeListener);

        setTimeout(() => {
            observer = new MutationObserver((mutationsList) => {
                requestAnimationFrame(() => {
                    for (const mutation of mutationsList) {
                        if (mutation.type === "childList") {
                            setTopForFloatedMenu();
                            break;
                        }
                    }
                });
            });

            observer.observe(document.body, {
                childList: true,
                subtree: true,
            });
        }, 0);
    });

    onBeforeUnmount(() => {
        if (resizeListener) {
            window.removeEventListener('resize', resizeListener);
        }

        if (observer) {
            observer.disconnect();
        }
    });

    return {};
}
